import { opts } from '../app';
import { BinProcess, ProxyInstance, } from '../models/interfaces';
import { AbortChildProcess } from '../services/controller_service';
import { getTotalSystemThreads } from './cpanel_service';

export async function BalanceServerLoad(): Promise<void> {
  // 1. loop through PROXIES for old disconnected bins and abort processes with zero call counts
  let shouldContinue = await abortProcessesAndContinue(true);

  // 2. if load is still high, abort the main processes with the call count is 0
  if (shouldContinue) {
    shouldContinue = await abortProcessesAndContinue();
  }

  // 3. if load is still too high then call this with 10sec timeout
  if (shouldContinue) {
    setTimeout(() => {
      BalanceServerLoad();
    }, 10000);
  }
}

async function abortProcessesAndContinue(abortOld = false): Promise<boolean> {
  const sortedProxies = sortProxyInstancesByActiveCallCountsAndOldestCallTime(opts.PROXIES);
  let shouldContinue = true;

  for (const pi of sortedProxies) {
    const bins = abortOld ? pi.OLD_BINS : new Map<string, BinProcess>([[pi.ABS_BIN_FILE_PATH as string, pi.CURRENT_BIN]]);

    for (const [key, bin] of bins) {
      if (!(await shouldReduceLoadAndContinue(pi, key, bin, abortOld))) {
        // No need to continue aborting if load is no longer high
        shouldContinue = false;
        break;
      }
    }

    if (!shouldContinue) {
      break;
    }
  }

  return shouldContinue;
}

function sortProxyInstancesByActiveCallCountsAndOldestCallTime(PROXIES: Map<string, ProxyInstance>): ProxyInstance[] {
  // Convert the map values to an array
  const proxiesArray: ProxyInstance[] = Array.from(PROXIES.values());

  // Sort the array based on ACTIVE_CALLS_COUNT and LAST_CALL_TIME
  proxiesArray.sort((a, b) => {
    if (a.CURRENT_BIN.ACTIVE_CALLS_COUNT === b.CURRENT_BIN.ACTIVE_CALLS_COUNT) {
      // If ACTIVE_CALLS_COUNT is the same, sort by LAST_CALL_TIME (ascending)
      return a.CURRENT_BIN.LAST_CALL_TIME - b.CURRENT_BIN.LAST_CALL_TIME;
    }
    // Otherwise, sort by ACTIVE_CALLS_COUNT (ascending)
    return a.CURRENT_BIN.ACTIVE_CALLS_COUNT - b.CURRENT_BIN.ACTIVE_CALLS_COUNT;
  });

  return proxiesArray;
}

export async function shouldReduceLoadAndContinue(pi: ProxyInstance, binKey: string, bp: BinProcess, isOldBin: boolean): Promise<boolean> {
  const curLimit = await getTotalSystemThreads() * 100 / parseInt(process.env.THREADS_LIMIT ?? '9999', 10);

  // Assuming 80% threshold
  const threshold = parseInt(process.env.SERVER_LOAD_THRESHOLD ?? '80', 10);
  if (curLimit> threshold) {
    if (bp.ACTIVE_CALLS_COUNT === 0) {
      await AbortChildProcess(pi, binKey, bp, isOldBin);
    }
    return true;
  }

  return false;
}
