import * as net from 'net';
import * as fs from 'fs';
import { spawn } from 'child_process';
import { ProxyInstance, BinProcess } from '../models/interfaces';
import Server from 'http-proxy';
import { opts } from '../app';
import path from 'path';
import os from 'os';
import { AddBinToLockFile, DeleteOldBinFromLockFile, ReadLockFile } from './lock_file_processor';
import { BalanceServerLoad } from './server_load_balancer';
import { LockInfo } from '../models/lock_info';
import { Mutex } from 'async-mutex';

export function CheckIfPortIsOnline(portNumber: string): Promise<boolean> {
  return new Promise(resolve => {
    const socket = new net.Socket();
    const onError = (e: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (_ev: unknown[]) => {
        console.log('check port open, error event: ' + e);
        socket.destroy();
        resolve(false);
      };
    };

    socket.setTimeout(1000);
    socket.once('error', onError('error'));
    socket.once('timeout', onError('timeout'));

    socket.connect(parseInt(portNumber, 10), '127.0.0.1', () => {
      socket.end();
      resolve(true);
    });
  });
}

export function StartProxyServerViaBinFile(absBinWorkingDir: string): Promise<[string, string, string, Error | undefined]> {
  return new Promise(resolve => {
    const binFile = CopyBinToTmpLocation(absBinWorkingDir);
    console.log('Starting a new BIN instance with the options: ', opts);

    let child;
    let pid = '';
    let isResolved = false; // Flag to track if the promise has been resolved

    const resolveOnce = (value: [string, string, string, Error | undefined]) => {
      if (!isResolved) {
        isResolved = true; // Set the flag to true when resolving for the first time
        resolve(value);
      }
    };

    try {
      child = spawn(binFile, {
        detached: true,
        cwd: absBinWorkingDir,
        env: {
          ...process.env,
          GOMAXPROCS: '1',
        },
      });
      child.unref(); // This ensures that the Node.js process will exit independently of the child
      pid = child.pid + '';
    } catch (e) {
      return resolveOnce(['', '', '', new Error('An err occurred while spawning the bin file : ' + e + ', path: ' + absBinWorkingDir)]);
    }

    const portMatcher = /.*?127\.0\.0\.1:(\d+).*/g;
    child.stdout?.on('data', (data) => {
      data = '' + data;
      if (data) {
        const r = portMatcher.exec(data);
        if (r) {
          AddBinToLockFile(absBinWorkingDir, binFile, r[1], pid).then((e) => {
            resolveOnce(e ? ['', '', '', e] : [binFile, r[1], pid, undefined]);
          });
        }
      }
    });

    child.stderr?.on('data', (data) => {
      console.warn('Warning: [std.err]: ' + data);
    });

    child.once('error', (error) => {
      resolveOnce(['', '', '', new Error('An err occurred while processing the BIN command [child.err]: ' + error.message)]);
    });

    child.once('exit', (code, signal) => {
      resolveOnce(['', '', '', new Error(`An err occurred while processing the BIN command [exit.err]: code:${code} signal: ${signal}`)]);
    });

    // Wait 5 sec before returning error, only if not resolved yet
    setTimeout(() => {
      resolveOnce(['', '', '', new Error("Could not get child process's port in the set time")]);
    }, 5000);
  });
}

export async function RenewProxyServerConfig(absBinWorkingDir: string, binLock: Mutex, pendingRestart: boolean): Promise<[LockInfo, Error | undefined]> {
  const r = await binLock.acquire();
  const li = ReadLockFile(absBinWorkingDir);
  const port = li.current_bin.port;
  let res: [string, string, string, Error | undefined] = ['', port, '', undefined]

  if (pendingRestart || !(port && (await CheckIfPortIsOnline(port)))) {
    // start the load balancer asynchronously
    BalanceServerLoad()
    res = await StartProxyServerViaBinFile(absBinWorkingDir);
    if (res[3] === undefined) {
      // add current bin to old list
      if (li.current_bin.bin !== '') {
        li.old_bins.push({ ...li.current_bin });
      }

      // update current bin
      li.current_bin.bin = res[0];
      li.current_bin.port = res[1];
      li.current_bin.pid = res[2];
    }
  }

  r(); // release the lock
  return [li, res[3]];
}

export async function AbortChildProcess(pi: ProxyInstance, fullBinFilePath: string, bp: BinProcess, isOldBin = true) {
  try {
    if (bp.PID === '') return;
    console.log('Aborting the child process: ', bp);
    process.kill(parseInt(bp.PID), 'SIGTERM');
  } catch (error) {
    console.log('Error while aborting the child process: ', error);
  }

  if (isOldBin) {
    pi.OLD_BINS.delete(fullBinFilePath);
  } else {
    opts.PROXIES.delete(pi.PATH_KEY);
  }
  DeleteOldBinFromLockFile(pi.BIN_LOCK, pi.ABS_BIN_WORKING_DIR, fullBinFilePath, isOldBin);
}

export function CreateCustomServerFromPort(port: string, host = '127.0.0.1'): Server.ServerOptions {
  return {
    target: {
      host: host,
      port: port,
    },
  };
}

export function CopyBinToTmpLocation(pathStr: string): string {
  try {
    const fullPath = path.resolve(pathStr, opts.BIN_NAME);
    const workDir = path.resolve(opts.WORK_DIR.replace(/~/, os.homedir()));
    const fullPathWithoutWorkDir = pathStr.replace(workDir, '');
    const unique_path = fullPathWithoutWorkDir.replace(/(?:^\W+)|(?:\W+$)/, '').replace(/\W/g, '_');
    const time = new Intl.DateTimeFormat('en-US', {
      weekday: 'short', month: 'short', day: 'numeric', year: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZoneName: 'short', hour12: true
    }).format(new Date()).toLowerCase().replace(/[,\s:]+/g, '_')
    const fileName = path.resolve(opts.TMP_DIR.replace(/~/, os.homedir()), workDir.replace(/^.*\//, ''), unique_path, unique_path + '_' + time + '.bin');

    fs.mkdirSync(path.dirname(fileName), { recursive: true });
    console.log(`Renaming the ${fullPath} file to: ==>`, fileName);
    fs.copyFileSync(fullPath, fileName);
    return fileName;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export async function DeletePath(absPath: string, deleteFolder = false, fn = () => {}) {
  try {
    if (deleteFolder) {
      fs.rmdirSync(absPath, { recursive: true });
      console.log('Deleted the folder: ', absPath);
    } else {
      await fs.promises.unlink(absPath);
      console.log('Deleted the file: ', absPath);
    }
  } catch (error) {
    console.log('Error while deleting the path: ', error);
  } finally {
    fn();
  }
}
