import * as http from 'http';
import { CheckPort, CheckPortResponse } from '../models/interfaces';
import { CheckIfPortIsOnline } from '../services/controller_service';

export function PortLivenessCheck(req: http.IncomingMessage, res: http.ServerResponse) {
  res.setHeader('Access-Control-Allow-Origin', '*');
  res.setHeader('Content-Type', 'application/json');

  const bodyParts: string[] = [];
  req.on('data', (chunk) => {
    bodyParts.push(chunk.toString());
  });

  req.on('end', () => {
    const body = bodyParts.join('');
    const bodyJson: CheckPort = JSON.parse(body);
    const port = bodyJson;

    // console.log(`Checking ports list ${port.ports.map(value => value.port)}`)

    const response: CheckPortResponse[] = [];
    Promise.all(port.ports.map(async (value) => {
      try {
        const r = await CheckIfPortIsOnline(value.port);
        if (r) {
          response.push({
            port: value.port,
            status: true,
          });
        } else {
          response.push({
            port: value.port,
            status: false,
          });
        }
      } catch (error) {
        // Handle the error here
        console.error(error);
      }
    })).then(() => {
      res.writeHead(200, { 'Content-Type': 'application/json' });
      res.write(JSON.stringify({
        'status': 'ok',
        'ports': response,
      }));
      res.end();
    }).catch((error) => {
      console.error(error);
    });
  });
}
