import * as fs from 'fs';
import { Convert, LockInfo } from '../models/lock_info';
import { opts } from '../app';
import path from 'path';
import { Mutex } from 'async-mutex';
import { DeletePath } from './controller_service';

export async function writeLockFile(binWorkDir: string, action: (lockInfo: LockInfo) => Promise<void>, m?: Mutex): Promise<Error | undefined> {
  const release = await m?.acquire();
  try {
    const lockInfo = ReadLockFile(binWorkDir);
    // update the current lock file object
    await action(lockInfo);

    const lockInfoJson = Convert.lockInfoToJson(lockInfo);
    fs.writeFileSync(path.resolve(binWorkDir, opts.LOCK_NAME), lockInfoJson);
  } catch (e) {
    console.error(binWorkDir, ':: Error in updating the lock file:', e);
    return e instanceof Error ? e : new Error(String(e));
  } finally {
    try {
      if (release) {
        release();
      }
    } catch (e) {
      console.error(binWorkDir, ':: Error in releasing the lock file:', e);
    }
  }
}

export async function SetLockFileToRestartState(m: Mutex, binWorkDir: string): Promise<Error | undefined> {
  return writeLockFile(binWorkDir, async lockInfo => {
    lockInfo.old_bins.push(lockInfo.current_bin);
    lockInfo.current_bin = { bin: '', port: '', pid: '' };
  }, m);
}

export async function AddBinToLockFile(binWorkDir: string, newBinPath: string, newBinPort: string, pid: string, m?: Mutex): Promise<Error | undefined> {
  return writeLockFile(binWorkDir, async lockInfo => {
    lockInfo.current_bin = { bin: newBinPath, port: newBinPort, pid: pid };
  }, m);
}

export async function DeleteOldBinFromLockFile(m: Mutex, binWorkDir: string, fullBinFilePath: string, isOldBin: boolean): Promise<Error | undefined> {
  return writeLockFile(binWorkDir, async lockInfo => {
    if (isOldBin) {
      lockInfo.old_bins = lockInfo.old_bins.filter(oldBin => {
        if (oldBin.bin === fullBinFilePath) {
          DeletePath(oldBin.bin)
          return false;
        }
        return true;
      });
    } else if (lockInfo.current_bin.bin === fullBinFilePath) {
      DeletePath(lockInfo.current_bin.bin)
      lockInfo.current_bin = { bin: '', port: '', pid: '' };
    }
  }, m);
}

export function ReadLockFile(workDir: string): LockInfo {
  try {
    const lockInfo = fs.readFileSync(path.resolve(workDir, opts.LOCK_NAME)).toString().trim();
    const lockInfoJson = Convert.toLockInfo(lockInfo);
    return lockInfoJson;
  } catch (e) {
    console.log(e);
    return {
      current_bin: { bin: '', port: '', pid: '' },
      old_bins: [],
    };
  }
}


