import { exec } from 'child_process';

export async function getTotalSystemThreads(): Promise<number> {
  return new Promise((resolve, reject) => {
      if (process.platform === 'win32') {
          // Windows platform
          exec('tasklist /FO CSV', (error, stdout) => {
              if (error) {
                  reject(error);
                  return;
              }
              // Count the number of lines in the output (excluding the header)
              const totalThreads = stdout.trim().split('\n').length - 1;
              resolve(totalThreads);
          });
      } else {
          // Unix-like platform (Linux, macOS)
          exec('ps -e -o nlwp', (error, stdout) => {
              if (error) {
                  reject(error);
                  return;
              }
              // Sum the number of threads from each process
              const totalThreads = stdout.trim().split('\n').reduce((acc, line) => {
                  const threads = parseInt(line.trim());
                  return isNaN(threads) ? acc : acc + threads;
              }, 0);
              resolve(totalThreads);
          });
      }
  });
}
