import * as http from 'http';
import { BinProcess, ServiceStatus } from '../models/interfaces';
import { opts } from '../app';

export function StatusController(_req: http.IncomingMessage, res: http.ServerResponse) {
  res.setHeader('Access-Control-Allow-Origin', '*');
  const response: ServiceStatus[] = [];
  opts.PROXIES.forEach((value, key) => {
    const port = value.CURRENT_BIN.NET_CONFIG.target?.port;
    if (port && value.ABS_BIN_FILE_PATH) {
      response.push({
        proxy_key: key,
        bin_port: port.toString(),
        abs_bin_file_path: value.ABS_BIN_FILE_PATH,
        status: true,
        active_calls_count: value.CURRENT_BIN.ACTIVE_CALLS_COUNT,
        pending_restart: value.PENDING_RESTART ?? false,
        last_call_time: new Date(value.CURRENT_BIN.LAST_CALL_TIME).toISOString(),
        pid: value.CURRENT_BIN.PID,

        old_bins: Array.from(value.OLD_BINS).reduce((obj, [key, value]) => {
          obj.push({
            key: key,
            value: value,
          });
          return obj;
        }, [] as { key: string, value: BinProcess }[]),
      });
    }
  });

  res.writeHead(200, { 'Content-Type': 'application/json' });
  res.write(JSON.stringify({
    'status': 'ok',
    'activeProxies': Array.from(response.values()),

  }));
  res.end();
}
