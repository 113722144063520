import { PortLivenessCheck } from './check_port_online_controller';
import { StatusController } from './status_controller';
import { ServeRootRestartHandler } from './serve_root_restart_controller';
import { StaticRequestHandler } from './static_request_controller';
import { ServeRootHandler } from './serve_root_controller';
import { FaviconController } from './favicon_controller';
import { FetchResourceUsage } from './cpanel_status_controller';
import * as http from 'http';
import { ListProcessHandler } from './list_process';

const routes = new Map<string, (req: http.IncomingMessage, res: http.ServerResponse) => void>();
routes.set('/favicon.ico', FaviconController);
routes.set('/ping', PortLivenessCheck);
routes.set('/status', StatusController);
routes.set('/restart', ServeRootRestartHandler);
routes.set('/list-process', ListProcessHandler);
routes.set('/', ServeRootHandler);
routes.set('/static', StaticRequestHandler);
routes.set('/cpanel-status', FetchResourceUsage);

export default routes;
