export type ParsedResult = {
  dir: string;
  port: string;
  rest: string;
};

const CACHE_LIMIT = 10000;
const cache: Record<string, ParsedResult> = {};
const cacheKeys: string[] = [];
let cachePointer = 0;

async function addToCache(key: string, value: ParsedResult) {
  if (cacheKeys.length === CACHE_LIMIT) {
    const oldestKey = cacheKeys[cachePointer];
    delete cache[oldestKey];
    cacheKeys[cachePointer] = key;
    cache[key] = value;
    cachePointer = (cachePointer + 1) % CACHE_LIMIT;
  } else {
    cacheKeys.push(key);
    cache[key] = value;
  }
}

export function parsePattern(input: string): ParsedResult {
  const cachedResult = cache[input];
  if (cachedResult) {
    return cachedResult;
  }

  const parts = input.split('/');
  const res: ParsedResult = {
    dir: '',
    port: '',
    rest: ''
  }

  if (parts[1].includes('*')) {
    [res.dir, res.port] = parts[1].split(/\*(?=\d+)/);
    res.dir = res.dir.replace(/^\*+|\*+$/, '') // update any starting/ending * = ''
      .replace(/\b\*+\b/g, '/') // update words interval's * = /
    res.rest = '/' + parts.slice(2).join('/');
  } else {
    const match = /services\.(\w+)\.v(\d+)\./.exec(parts[1]);
    if (match) {
      res.dir = `${parts[0]}${match[1]}/services/v${match[2]}`;
      res.rest = '/' +parts[1]+'/' + parts.slice(2).join('/');
    } else {
      res.rest = '/' + parts.slice(1).join('/');
    }
  }

  if (res.port === undefined) {
    res.port = '';
  }

  addToCache(input, res);
  return res;
}

export default parsePattern;
