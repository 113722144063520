import { opts } from '../app';
import { AbortChildProcess } from './controller_service';

export function RunBackgroundJobs() {
  // start abort idle process job
  abortOldIdleProcess()

  // add 24 hr interval job for old bin deletions that will
  // delete versioned bin i.e. /vX/app.bin if it <cross 3months + if its versioned>
}



async function abortOldIdleProcess() {
  // create a background job to terminate the idle proxy instances
  const callback = () => {
    for (const pi of opts.PROXIES.values()) {
      // iterate old bins
      for (const [k, v] of pi.OLD_BINS) {
        if (Date.now() - pi.CURRENT_BIN.LAST_CALL_TIME >= opts.IDLE_OLD_BIN_ABORT_TIMEOUT_MS && pi.CURRENT_BIN.ACTIVE_CALLS_COUNT === 0) {
          AbortChildProcess(pi, k, v);
        }
      }
    }
  };
  setInterval(callback, opts.IDLE_OLD_BIN_ABORT_TIMEOUT_MS);
}
