import * as http from 'http';
import Server, { ProxyTargetUrl } from 'http-proxy';
import { Mutex } from 'async-mutex';
import stream from 'stream';
import { ProxyInstance, NodeError, GatewayOptions, BinProcess } from './models/interfaces';
import { RenewProxyServerConfig, CreateCustomServerFromPort } from './services/controller_service';
import path from 'path';
import { Socket } from 'net';
import routes from './controllers/controller';
import { parsePattern } from './utils/path_parser';
import dotenv from 'dotenv';
import { RunBackgroundJobs } from './services/background_jobs';

// Load environment variables from .env file
dotenv.config();

export const opts: GatewayOptions = {
  BIN_NAME: process.env.BIN_NAME ?? 'app.bin',
  LOCK_NAME: process.env.LOCK_NAME ?? 'info.lock',
  PROXIES: new Map<string, ProxyInstance>(),
  ROOT_CONTROLLERS: routes,
  ROOT_SERVER_PORT: 8015,
  WORK_DIR: process.env.WORK_DIR ?? './',
  TMP_DIR: process.env.TMP_DIR ?? '~/tmp',
  IDLE_OLD_BIN_ABORT_TIMEOUT_MS: parseInt(process.env.IDLE_ABORT_TIMEOUT_MS ?? '0', 10) || 15 * 6e4,
};

export async function getProxyInstance(pathKey: string, forceRenew = false): Promise<[ProxyInstance, Error | undefined]> {
  let pInstance = opts.PROXIES.get(pathKey);
  if (pInstance?.CURRENT_BIN.NET_CONFIG.target && !pInstance.PENDING_RESTART && !forceRenew) {
    return [pInstance, undefined];
  }

  const mutex = pInstance?.BIN_LOCK ?? new Mutex();
  const binWorkDir = path.resolve(opts.WORK_DIR, pathKey);
  const [li, err] = await RenewProxyServerConfig(binWorkDir, mutex, pInstance?.PENDING_RESTART ?? false);
  if (err) {
    return [{} as ProxyInstance, err];
  }

  const oldBins = pInstance?.OLD_BINS ?? new Map<string, BinProcess>();
  li.old_bins.forEach(oldBin => {
    oldBins.set(oldBin.bin, {
      PID: oldBin.pid,
      LAST_CALL_TIME: 0,
      ACTIVE_CALLS_COUNT: 0,
      NET_CONFIG: {
        target: {
          host: '127.0.0.1',
          port: oldBin.port,
        },
      },
    });
  });

  pInstance = {
    BIN_LOCK: mutex,
    ABS_BIN_WORKING_DIR: binWorkDir,
    ABS_BIN_FILE_PATH: li.current_bin.bin,
    PENDING_RESTART: false,
    CURRENT_BIN: {
      LAST_CALL_TIME: Date.now(),
      ACTIVE_CALLS_COUNT: 0,
      NET_CONFIG: {
        target: {
          host: '127.0.0.1',
          port: li.current_bin.port,
        }
      },
      PID: li.current_bin.pid,
    },
    OLD_BINS: oldBins,
    PATH_KEY: pathKey,
  };

  opts.PROXIES.set(pathKey, pInstance);
  return [pInstance, undefined];
}

export async function getProxyRequest(req: http.IncomingMessage): Promise<[string | null, Promise<[ProxyInstance, Error | undefined]>]> {
  const m = parsePattern(req.url!);
  console.log('Parsed request: ', m);

  let pi: Promise<[ProxyInstance, Error | undefined]> = Promise.resolve([{} as ProxyInstance, undefined]);
  if (m.dir !== '') {
    // update request url path
    req.url = m.rest;

    pi = getProxyInstance(m.dir);
    // port is either empty or a number(string)
    return [m.port, pi];
  }

  return [null, pi];
}

export function forwardToRootController(req: http.IncomingMessage, res: http.ServerResponse) {
  // take the first part of the path
  const rootPath = req.url?.split('/')[1];
  console.log('Forwarding to the root controller: ', rootPath);

  const controller = opts.ROOT_CONTROLLERS.get('/' + rootPath);
  if (controller) {
    controller(req, res);
  } else {
    res.writeHead(404);
    res.end();
  }
}

const proxyServer = Server.createProxyServer();
const proxyErrorHandler = async (err: NodeError, req: http.IncomingMessage, res: http.ServerResponse, _target: ProxyTargetUrl | undefined, method = 'web', pi: ProxyInstance, socket?: stream.Duplex, head?: Buffer) => {
  // if the server failed to respond due to a port closure
  // - reset the proxy instance
  // - start the server
  // - do the request again
  if (err.code === 'ECONNREFUSED') {
    let e
    [pi, e] = await getProxyInstance(pi.PATH_KEY, true);
    if (e !== undefined) {
      console.log(`Error renewing proxy server config: ${e}`);

      res.statusCode = 500;
      res.write('A fatal error occurred!');
      res.write(err.toString());
      res.end();
      return;
    }

    if (method === 'web') {
      webHandler(req, res, pi);
    } else {
      wsHandler(req, socket as stream.Duplex, head as Buffer, pi);
    }
  }
};

const webHandler = async (req: http.IncomingMessage, res: http.ServerResponse, pi?: ProxyInstance) => {
  let err: Error | undefined;
  let port: string | null = null; // this port is external local port i.e. /dir*port/

  if (pi === undefined) {
    const [proxyPort, proxyPromise] = await getProxyRequest(req);
    port = proxyPort;
    const [proxyInstance, proxyError] = await proxyPromise;
    pi = proxyInstance;
    err = proxyError;
  }

  // invoking non proxy endpoints
  if (port === null && !pi?.CURRENT_BIN?.NET_CONFIG.target?.port) {
    return forwardToRootController(req, res);
  }

  if (!err && pi) {
    proxyServer.once('proxyReq', (_proxyReq, _req, _res) => {
      pi!.CURRENT_BIN.LAST_CALL_TIME = Date.now();
      pi!.CURRENT_BIN.ACTIVE_CALLS_COUNT++;
    });

    proxyServer.once('proxyRes', (_proxyReq, _req, _res) => {
      pi!.CURRENT_BIN.ACTIVE_CALLS_COUNT--;
    });

    let so: Server.ServerOptions;
    // for: proxy but external local port i.e. /dir*port/
    if (!port) {
      so = pi.CURRENT_BIN.NET_CONFIG as Server.ServerOptions
    } else {
      so = CreateCustomServerFromPort(port as string);
    }

    proxyServer.web(req, res, so, (err: Error, req: http.IncomingMessage, res: http.ServerResponse | Socket, target: ProxyTargetUrl | undefined): void => {
      proxyErrorHandler(err as NodeError, req, res as http.ServerResponse, target, 'web', pi as ProxyInstance);
    });
  } else if (err) {
    res.statusCode = 500;
    res.write('An error occurred: ' + err.message);
    res.end();
  } else {
    res.statusCode = 500;
    res.write('An internal server issue occurred');
    res.end();
  }
};

const wsHandler = function (req: http.IncomingMessage, socket: stream.Duplex, head: Buffer, pi?: ProxyInstance) {
  let err: Error | undefined;
  let port: string | null = null;

  if (!pi) {
    getProxyRequest(req).then(r => {
      port = r[0];
      r[1].then(p => {
        pi = p[0];
        err = p[1];
      });
    });
  }

  // currently, theres no non proxy endpoints for WS
  if (port === null && pi) {
    socket.write('Invalid proxy port');
    socket.end();
  }

  if (err === undefined && pi) {
    proxyServer.once('proxyReqWs', (_proxyReq, _req, _socket, _options, _head) => {
      pi!.CURRENT_BIN.LAST_CALL_TIME = Date.now();
      pi!.CURRENT_BIN.ACTIVE_CALLS_COUNT++;
    });

    proxyServer.once('close', (_code, _reason) => {
      pi!.CURRENT_BIN.ACTIVE_CALLS_COUNT--;
    });

    let so: Server.ServerOptions;
    // for: proxy but external local port i.e. /dir*port/
    if (!port) {
      so = pi.CURRENT_BIN.NET_CONFIG as Server.ServerOptions
    } else {
      so = CreateCustomServerFromPort(port as unknown as string);
    }

    proxyServer.ws(req, socket, head, so, (err: Error, req: http.IncomingMessage, res: http.ServerResponse | Socket, target: ProxyTargetUrl | undefined) => {
      proxyErrorHandler(err as NodeError, req, res as http.ServerResponse, target, 'ws', pi as ProxyInstance, socket, head);
    });
    pi.CURRENT_BIN.ACTIVE_CALLS_COUNT--;
  } else if (err) {
    socket.write('An error occurred: ' + err.message);
    socket.end();
  } else {
    socket.write('An internal server issue occurred');
    socket.end();
  }
};

// start async background jobs
RunBackgroundJobs();

// apply cors headers
proxyServer.on('proxyRes', (_proxyRes, _req, res) => {
  res.setHeader('Access-Control-Allow-Origin', '*');
});

// attach websocket listener and listen for connections
http.createServer(webHandler).on('upgrade', wsHandler).listen(opts.ROOT_SERVER_PORT);
console.log('server started at: http://localhost:' + opts.ROOT_SERVER_PORT);

