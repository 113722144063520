import { IncomingMessage, ServerResponse } from 'http';
import { exec as execCallback } from 'child_process';
import { promisify } from 'util';
import os from 'os';

const exec = promisify(execCallback);

/**
 * The ListProcessHandler function is an asynchronous function that handles a request to list
 * processes, extracts headers and values from the request URL, executes a command to get the list of
 * processes, maps the output to process objects based on the headers and values, and returns the list
 * of processes as a JSON response.
 * @param {IncomingMessage} req - The `req` parameter is of type `IncomingMessage`, which represents
 * the incoming HTTP request from the client. It contains information such as the request method,
 * headers, URL, and body.
 * @param {ServerResponse} res - The `res` parameter is the `ServerResponse` object, which represents
 * the response that will be sent back to the client. It is used to set response headers, write
 * response data, and end the response.
 * @returns a JSON response with the status and processes. The status is set to 'ok' and the processes
 * are an array of objects, where each object represents a process and contains key-value pairs for the
 * process information.
 *
 * URL example: localhost:8015/list-process/user/omar/command/.bin
 */
export async function ListProcessHandler(req: IncomingMessage, res: ServerResponse) {
  // Set the 'Access-Control-Allow-Origin' header only for trusted domains
  const trustedDomains = ['astronlab.com', 'localhost', '::', '::1'];
  if (req.headers.origin && trustedDomains.some(domain => req.headers.origin?.includes(domain))) {
    res.setHeader('Access-Control-Allow-Origin', req.headers.origin);
  }

  // Extract the headers and values from the path variable
  const headersAndValues = req.url?.split('/').slice(2);
  const map: { [key: string]: string } = {};
  if (headersAndValues) {
    for (let i = 0; i < headersAndValues.length; i += 2) {
      map[headersAndValues[i].toUpperCase()] = headersAndValues[i + 1];
    }
  }

  let command = 'ps aux';
  if (os.platform() === 'win32') {
    command = 'tasklist';
  }

  try {
    // Execute the command to get the list of processes
    const { stdout, stderr } = await exec(command);

    if (stderr) {
      console.error(`Error executing command: ${stderr}`);
      res.writeHead(500, { 'Content-Type': 'application/json' });
      res.end(JSON.stringify({ status: 'error', message: 'Internal Server Error' }));
      return;
    }

    // Split the output into lines
    const lines: string[] = stdout.trim().split('\n');

    // Remove the header line
    const header: string[] | undefined = lines.shift()?.trim().split(/\s+/);

    // Create an array to store the process objects
    const processes: { [key: string]: string }[] = [];

    // Iterate over each line and create a process object
    lines.forEach((line) => {
      const values = line.trim().split(/\s+/);
      const processObj: { [key: string]: string } = {};

      // Map each value to its corresponding header field
      let match = true;
      header?.forEach((field, index) => {
        const v = map[field];
        if (v && values[index].indexOf(v) < 0) {
          match = false;
          return;
        }
        processObj[field] = values[index];
      });

      // Add the process object to the array if the match found
      if (match) {
        processes.push(processObj);
      }
    });

    res.writeHead(200, { 'Content-Type': 'application/json' });
    res.end(JSON.stringify({ status: 'ok', processes }));
  } catch (error) {
    console.error(`Error executing command: ${error}`);
    res.writeHead(500, { 'Content-Type': 'application/json' });
    res.end(JSON.stringify({ status: 'error', message: 'Internal Server Error' }));
  }
}
