import * as http from 'http';
import path from 'path';
import fs from 'fs';

export function StaticRequestHandler(req: http.IncomingMessage, res: http.ServerResponse) {
  const filename = req.url!;
  console.log(`Request for ${filename} received.`)

  // check if file exists
  const staticPath = path.resolve(__dirname, 'public', filename);
  fs.access(staticPath, fs.constants.F_OK, (err) => {
    if (err) {
      // File does not exist
      res.statusCode = 404;
      res.end('File not found');
    } else {
      // File exists, serve it
      const fileStream = fs.createReadStream(staticPath);

      fileStream.on('open', () => {
        res.setHeader('Content-Type', getContentType(staticPath));
        fileStream.pipe(res);
      });

      fileStream.on('error', (error) => {
        console.error(error);
        res.statusCode = 500;
        res.end('Internal Server Error');
      });
    }
  });
}

export function getContentType(filename: string): string {
  const ext = path.extname(filename).toLowerCase();

  switch (ext) {
    case '.html':
      return 'text/html';
    case '.css':
      return 'text/css';
    case '.js':
      return 'text/javascript';
    case '.png':
      return 'image/png';
    case '.jpg':
    case '.jpeg':
      return 'image/jpeg';
    default:
      return 'application/octet-stream';
  }
}
