import * as http from 'http';
import { ProxyInstance, BodyParts } from '../models/interfaces';
import { SetLockFileToRestartState } from '../services/lock_file_processor';
import { opts } from '../app';
import fs from 'fs';
import path from 'path';

export function ServeRootRestartHandler(_req: http.IncomingMessage, res: http.ServerResponse) {
  res.setHeader('Access-Control-Allow-Origin', '*');
  let pi: ProxyInstance | undefined;

  const bodyParts: string[] = [];
  _req.on('data', (chunk) => {
    bodyParts.push(chunk.toString());
  });

  _req.on('end', () => {
    const body = bodyParts.join('');
    const bodyJson: BodyParts = JSON.parse(body);
    pi = opts.PROXIES.get(bodyJson.bin_path_key);
    const binPath = path.resolve(opts.WORK_DIR, bodyJson.bin_path_key, opts.BIN_NAME)
    if (!fs.existsSync(binPath)) {
      res.writeHead(500, { 'Content-Type': 'application/json' });
      res.write('Bin file not found');
      res.end();
      return;
    }

    if (pi) {
      // to reduce warmup latency let's update the lockfile now rather than on next invoke time
      if (!pi.PENDING_RESTART) {
        SetLockFileToRestartState(pi.BIN_LOCK, pi.ABS_BIN_WORKING_DIR)
          .then((r) => {
            if (r) {
              res.writeHead(500, { 'Content-Type': 'application/json' });
              res.write('Error creating lock file');
              res.end();
            }
          })
          .catch(() => {
            res.writeHead(500, { 'Content-Type': 'application/json' });
            res.write('Error creating lock file');
            res.end();
          });
      }

      // update current proxy instance too
      pi.PENDING_RESTART = true;
      if (pi.ABS_BIN_FILE_PATH) {
        pi.OLD_BINS.set(pi.ABS_BIN_FILE_PATH, pi.CURRENT_BIN);
      }
    }

    res.writeHead(200, { 'Content-Type': 'application/json' });
    res.write(JSON.stringify({
      'status': 'The service will restart on next http call',
      'bin_path_key': bodyJson.bin_path_key,
    }));
    res.end();

  });
}
