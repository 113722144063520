import * as http from 'http';
import { getTotalSystemThreads } from '../services/cpanel_service';

export async function FetchResourceUsage(_req: http.IncomingMessage, res: http.ServerResponse): Promise<void> {
  res.setHeader('Access-Control-Allow-Origin', '*');
  try {
    const data = await getTotalSystemThreads();
    res.writeHead(200, { 'Content-Type': 'application/json' });
    res.write(JSON.stringify(data));
    res.end();
  }
  catch (err) {
    console.error(err);
    res.writeHead(500, { 'Content-Type': 'application/json' });
    res.write(JSON)
    res.end();
  }

}
